// src/context/ThemeContext.js
import React, { createContext, useState, useMemo, useContext } from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from "@mui/material";

const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState("dark"); // Imposta il tema dark come predefinito

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "dark" && {
            background: {
              default: "#111", // Imposta il colore di sfondo su #131313
              paper: "#111", // Colore di sfondo dei componenti, puoi modificarlo come preferisci
            },
            text: {
              primary: "#ffffff", // Imposta il testo su bianco
              secondary: "#aaaaaa", // Imposta un colore secondario per il testo
            },
          }),
          ...(mode === "light" && {
            background: {
              default: "#fff", // Imposta il colore di sfondo per il tema chiaro
              paper: "#fff", // Colore di sfondo dei componenti in modalità chiara
            },
            text: {
              primary: "#000000", // Imposta il testo su nero
              secondary: "#333333", // Imposta un colore secondario per il testo in modalità chiara
            },
          }),
        },
      }),
    [mode]
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme, theme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export default ThemeContext;
