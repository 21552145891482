module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["it","en"],"defaultLanguage":"en","siteUrl":"https://shareboost.giampietropregnolato.com","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/choose","getLanguageFromPath":true},{"matchPath":"/:lang?/customize","getLanguageFromPath":true},{"matchPath":"/:lang?/boost","getLanguageFromPath":true},{"matchPath":"/:lang?/login","getLanguageFromPath":true},{"matchPath":"/:lang?/signup","getLanguageFromPath":true},{"matchPath":"/:lang?/dashboard","getLanguageFromPath":true},{"matchPath":"/:lang?/price","getLanguageFromPath":true},{"matchPath":"/:lang?/install","getLanguageFromPath":true},{"matchPath":"/:lang?/404","getLanguageFromPath":true}],"redirect":false,"generateDefaultLanguagePage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
