// gatsby-browser.js
import React from "react";

import { ThemeContextProvider } from "./src/context/ThemeContext";
import ButtonProvider from "./src/context/ButtonContext";
import "bulma/css/bulma.min.css";

export const wrapRootElement = ({ element }) => (
  <ThemeContextProvider>
    <ButtonProvider>{element}</ButtonProvider>
  </ThemeContextProvider>
);
