// src/context/ButtonContext.js
import React, { createContext, useState, useEffect } from "react";

export const ButtonContext = createContext();

const ButtonProvider = ({ children }) => {
  const [selectedServices, setSelectedServices] = useState([]);

  const [boostOptions, setBoostOptions] = useState({
    customColor: "#209cee",
    buttonImage: "",
    buttonIcon: "",
    buttonStyle: "classic",
    buttonText: "",
    openingDirection: "up",
    animation: false,
    border: false,
    notification: false,
    backgroundStyle: "solid",
    buttonSize: 50, // Questo valore è in percentuale
    borderRadius: 100,
    shadow: 0,
    opacity: 100,
    position: "right",
  });

  // Carica le impostazioni salvate all'avvio
  useEffect(() => {
    const savedBoostOptions =
      JSON.parse(localStorage.getItem("boostOptions")) || {};
    setBoostOptions((prevOptions) => ({
      ...prevOptions,
      ...savedBoostOptions,
    }));
  }, []);

  const updateBoostOptions = (newOptions) => {
    setBoostOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        ...newOptions,
      };

      // Salva le impostazioni aggiornate nel localStorage
      localStorage.setItem("boostOptions", JSON.stringify(updatedOptions));

      // Applica le regole specifiche per "rectangle"
      if (updatedOptions.buttonStyle === "rectangle") {
        updatedOptions.borderRadius = Math.max(updatedOptions.borderRadius, 25);
        updatedOptions.buttonText = updatedOptions.buttonText.slice(0, 15); // Limita la lunghezza del testo
        updatedOptions.width = "100%"; // Forza il bottone ad avere larghezza 100% in modalità "rectangle"
      }

      return updatedOptions;
    });
  };

  const [previewUrl, setPreviewUrl] = useState("");

  return (
    <ButtonContext.Provider
      value={{
        selectedServices,
        setSelectedServices,
        boostOptions,
        setBoostOptions,
        previewUrl,
        setPreviewUrl,
        updateBoostOptions,
      }}
    >
      {children}
    </ButtonContext.Provider>
  );
};

export default ButtonProvider;
